import './App.css';
import gifAnimado from './assets/shrek-shrek-rizz.gif';

function App() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <img src={gifAnimado} alt="Gif animado" style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
  );
}

export default App;
